<script>
import Layout from "@/layouts/main.vue";

export default {
  page: {
    title: "Starter",
    meta: [{name: "description", content: ''}],
  },
  data() {
    return {
      accountSlug: '',
      account: {},
      tabs: [
        {
          value: 'terminal_profile_main',
          label: 'Expenses',
          active_paths: ['terminal_profile_main']
        },
      ]
    };
  },
  methods: {
    isActiveClass(tab) {
      return tab.active_paths.includes(this.$route.name)
    }
  },
  components: {
    Layout,
  },
  mounted() {
    this.accountSlug = this.$route.params.slug
  },
  watch: {
    accountSlug(newVal) {
      if (newVal === '') return
      this.getCompanyAccount()
    }
  },
};
</script>

<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img
            src="@/assets/images/profiles/company-profile-background.png"
            alt=""
            class="profile-wid-img"
        />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row align-items-center g-4">
        <div class="col-auto">
          <img src="@/assets/images/user.png" class="rounded img-fluid" alt="" style="height: 60px;">
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2" v-if="Object.keys(account).length > 0">
            <h3 class="text-white mb-1">
              {{ account.name }}
            </h3>
            <p class="text-white-75">
              Counterparty
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-8" v-if="$route.params.contract_slug">
          <div id="contract-balance"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">

        <div class="d-flex">
          <ul class="nav nav-pills profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
            <li class="nav-item" v-for="tab in tabs" :key="tab">
              <router-link :to="{name: tab.value}"
                           class="nav-link fs-14 cursor-pointer"
                           role="tab"
                           :class="{'active': isActiveClass(tab)}"
              >
                <i class="ri-airplay-fill d-inline-block d-md-none"></i>
                <span class="d-none d-md-inline-block">{{ tab.label }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div v-if="Object.keys(account).length > 0" class="tab-content pt-4 text-muted">
          <div class="tab-pane active" id="overview-tab" role="tabpanel">
            <template v-if="this.$route.name === 'terminal_profile_main'">
              Terminal
            </template>
            <template v-else>
              <router-view></router-view>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>